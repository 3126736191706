<template>
  <div id="video_child">
    <!--vant van-swipe 滑动组件 -->
    <!-- <div class="title">
      <svg-icon
        icon-class="arrow_right"
        @click="
          $route.query.taskId
            ? $router.push('/userData/signIn')
            : $router.push('homePage')
        "
      />
    </div> -->
    <van-swipe
      :show-indicators="false"
      @change="onChange"
      vertical
      :loop="true"
      :initial-swipe="current"
      ref="swipe"
    >
      <van-swipe-item v-for="list in videoList" :key="list.id">
        <div class="video_container">
          <video
            class="video_box"
            width="100%"
            height="100%"
            webkit-playsinline="true"
            x5-playsinline="true"
            x-webkit-airplay="allow"
            x5-video-player-type="h5"
            x5-video-player-fullscreen="true"
            x5-video-orientation="portrait"
            playsinline
            preload
            :playOrPause="playOrPause"
            :poster="list.cover ? list.cover : ''"
            :src="list.filePath"
            @click="pauseVideo"
            @ended="onPlayerEnded($event)"
          ></video>
          <!-- 播放暂停按钮 -->
          <transition name="van-fade">
            <button class="play_btn" @click="pauseVideo" v-show="iconPlayShow">
              <svg-icon icon-class="play" class="icon_play" />
            </button>
          </transition>
        </div>
        <!-- 底部作品描述 -->
        <div class="production_box">
          <div
            class="buy"
            @click="list.commId == 0 ? $router.push('/') : (show = true)"
          >
            <svg-icon icon-class="gouwu_icon" /><span>{{
              list.commId == 0 ? "商城首页" : "购物 | 商品购买点这里"
            }}</span>
          </div>
          <div class="production_name">
            {{ list.commodityName }}
          </div>
          <div class="production_des">
            {{ list.videoIntroduction }}
          </div>
          <!-- <van-button
            style="width: 100%"
            round
            type="info"
            size="small"
            color="rgb(251, 93, 32)"
            @click="show = true"
            >立即购买</van-button
          > -->
          <div class="right"></div>
          <div
            ref="control"
            class="control"
            :style="playOrPause ? 'opacity: 0.3' : ''"
          >
            <!-- { transition: playOrPause ? '0.4s width' : '' }, -->
            <div
              class="progress"
              :style="{
                width: `${progressWidth}%`,
              }"
              style="transition: 0.4s width"
              ref="progress"
            >
              <div
                class="slider_circle"
                @touchmove="handleSliderMouseDown"
                @touchend="end"
              >
                <span></span>
              </div>
              <div class="slider_circle_large" />
            </div>
          </div>
        </div>

        <!-- <div class="function_box">
          <svg-icon icon-class="share" @click="copyUrl" />
        </div> -->
      </van-swipe-item>
    </van-swipe>
    <div class="empty" v-if="!allVideo.length">
      <svg-icon icon-class="none_video" v-if="!allVideo.length && !isLoading" />
      <p v-if="!allVideo.length && !isLoading">暂无视频</p>
    </div>
    <van-loading size="50" v-show="isLoading" />
    <van-action-sheet v-model="show" title="商品详情" class="goods_box">
      <goods
        v-if="show"
        :barcode="videoList[this.current].commodityPriceDetailsRsp.barcode"
        :enterpriseId="
          videoList[this.current].commodityPriceDetailsRsp.enterpriseId
        "
      />
    </van-action-sheet>

    <div class="wrapper" v-show="homeShow">
      <img src="@/assets/images/logo.png" alt="" />

      <p>正品汇商城</p>

      <span @click="$router.push('/')">去首页</span>
    </div>
  </div>
</template>

<script>
import { WechatSharing } from "@/utils/sdk";
import wx from "weixin-js-sdk"; //微信sdk依赖
import goods from "../Goods/index.vue";

export default {
  name: "video-child",
  components: {
    goods,
  },
  data() {
    return {
      current: 0, //当前视频索引
      allVideo: [],
      videoList: [], //视频列表
      index: 0,
      show: false, //购买弹窗
      pageNum: 1, //页码
      playOrPause: false, //播放状态
      iconPlayShow: true, //播放按钮
      isLoading: true,
      progressWidth: 0,
      homeShow: false,
    };
  },
  watch: {
    show(val) {
      this.pauseVideo();
      // if (this.playOrPause == true) this.pauseVideo();
      // if (val == false) this.pauseVideo();
    },
  },
  created() {
    this.getvideoList();
  },
  deactivated() {
    this.iconPlayShow = true;
    this.playOrPause = false;
  },
  mounted() {},
  methods: {
    async getvideoList() {
      const data = await this.$API.homePage.queryVideoList({
        object: {
          enableState: true,
          enterpriseId: "",
          examineState: 1,
          id: "",
          like: "",
          share: this.$route.query.v ? this.$route.query.v : "",
        },
      });
      this.allVideo = data.data.data;
      this.isLoading = false;
      this.total = data.data.total;
      this.videoList = this.allVideo.slice(0, this.pageNum * 2);
      let info = this.videoList[this.current];
      let shareInfo = {
        title: info.commId == 0 ? "正品汇商城" : info.commodityName,
        desc: info.videoIntroduction,
        imgUrl:
          info.commId == 0 ? require("@/assets/images/logo.png") : info.cover,
        link:
          info.commId == 0
            ? window.location.hash
            : `${window.location.hash}&v=${info.id}`,
      };
      WechatSharing(shareInfo);
      this.$nextTick(function () {
        this.onChange(0, 1);
      });
    },
    //滑动改变播放的视频
    onChange(index, i) {
      // document.querySelectorAll("video")[index].play();
      document.querySelectorAll("video")[this.current].pause();
      this.iconPlayShow = true;
      this.playOrPause = false;
      this.progressWidth = 0;
      this.current = index; //当前播放第几个

      let video = document.querySelectorAll("video")[this.current];
      if (this.videoList[this.current].commId == 0) {
        video.removeEventListener("ended", this.ended);
        video.addEventListener("ended", this.ended);
      }
      this.homeShow = false;
      let arr = [
        "canplay",
        "play",
        "waiting",
        "durationchange",
        "timeupdate",
        "loadeddata",
        "canplaythrough",
      ];
      arr.forEach((list, i) => {
        video.removeEventListener(list, this[list]);
        video.addEventListener(list, this[list]);
      });
      // document.addEventListener("WeixinJSBridgeReady", this.wx, false);
      let info = this.videoList[this.current];
      let shareInfo = {
        title: info.commId == 0 ? "正品汇商城" : info.commodityName,
        desc: info.videoIntroduction,
        imgUrl:
          info.commId == 0 ? require("@/assets/images/logo.png") : info.cover,
        link:
          info.commId == 0
            ? window.location.hash
            : `${window.location.hash}&v=${info.id}`,
      };
      WechatSharing(shareInfo);

      if (
        this.current == this.videoList.length - 1 &&
        this.videoList.length != this.allVideo.length
      ) {
        this.pageNum++;
        this.videoList = this.allVideo.slice(0, this.pageNum * 2);
      }
      if (i) return;
      this.pauseVideo();
    },
    waiting(e) {
      this.isLoading = true;
      this.iconPlayShow = false;
      console.log("加载监听");
      // alert("加载监听")
    },
    play(e) {
      this.isLoading = false;
      this.iconPlayShow = false;
      // let video = document.querySelectorAll("video")[this.current];
      // video.addEventListener("timeupdate", this.timeupdate);
      console.log("播放监听");
      // alert("播放监听");
      this.homeShow = false;
    },
    progress() {
      // alert("请求数据");
    },
    ended(e) {
      // console.log("结束监听");
      this.homeShow = true;
      this.iconPlayShow = false;
    },
    canplay(e) {
      // this.iconPlayShow = false;
      this.isLoading = false;
      console.log("缓冲后播放监听");
      // alert("缓冲后播放监听");
    },
    timeupdate(e) {
      //目前的播放位置已更改时，播放时间更新
      //duration总时长
      //currentTime当前播放时长
      let video = document.querySelectorAll("video")[this.current];
      let totalT = video.duration;
      let presentT = video.currentTime;
      this.progressWidth = (presentT / totalT) * 100;
    },

    loadeddata(e) {
      this.isLoading = true;
      console.log("提示当前帧的数据是可用的");
    },
    pauseVideo() {
      //暂停\播放
      // this.isLoading = true;
      this.playOrPause = !this.playOrPause;
      let video = document.querySelectorAll("video")[this.current];
      if (this.playOrPause) {
        video.play();
      } else {
        video.pause();
        this.iconPlayShow = true;
        this.isLoading = false;
      }
    },
    onPlayerEnded() {
      this.iconPlayShow = true;
      this.playOrPause = false;
      // this.$refs.swipe.next();
    },
    handleSliderMouseDown(event) {
      // console.log(navigator);
      // navigator.vibrate([300, 300]);
      this.$refs.progress[this.current].style.transition = "";
      event.preventDefault();
      let video = document.querySelectorAll("video")[this.current];
      video.pause();
      this.playOrPause = false;
      this.progressWidth = (event.touches[0].clientX / window.innerWidth) * 100;
    },
    end() {
      this.$refs.progress[this.current].style.transition = "width 0.4s ease 0s";
      //width 0.4s ease 0s
      let video = document.querySelectorAll("video")[this.current];
      // console.log(this.progressWidth);
      video.currentTime = (this.progressWidth / 100) * video.duration;
      this.playOrPause = true;
      video.play();
    },
    //复制当前链接
    copyUrl() {
      console.log(window.location);
      let httpUrl =
        window.location.href + `&v=${this.videoList[this.current].id}`;
      var oInput = document.createElement("input");
      oInput.value = httpUrl;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      oInput.remove();
      this.Toast("复制链接成功");
    },
    wx() {
      wx.config({
        // 配置信息, 即使不正确也能使用 wx.ready
        debug: false,
        appId: "gh_1a8c118653f8",
        timestamp: 1,
        nonceStr: "",
        signature: "",
        jsApiList: [],
      });
      wx.ready(function () {
        console.log(this);
        let video = document.querySelectorAll("video")[this.current];
        video.play();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#video_child {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 997;
  // max-width: 380px;
  //   margin: 0 auto;
  .title {
    position: absolute;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    background: transparent;
    .svg-icon {
      font-size: 16px;
      transform: rotate(180deg);
      color: #fff;
    }
    //  background: rgba(255, 255, 255, 0.1);
  }
  .van-swipe {
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;
    .van-swipe-item {
      // background: #fb5d20;
      background: rgb(0, 35, 125);
      .video_container {
        height: 100%;
        .video_box {
          object-fit: contain;
          object-position: 50%;
        }
      }
    }
    .production_box {
      z-index: 1001;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 15px;
      margin-bottom: 45px;
      color: #fff;
      // width: 100%;
      // background: -webkit-linear-gradient(
      //   top,
      //   rgba(0, 0, 0, 0.3),
      //   rgba(0, 0, 0, 0)
      // ); /* Safari 5.1 - 6.0 */
      // background: -o-linear-gradient(
      //   top,
      //   rgba(0, 0, 0, 0.3),
      //   rgba(0, 0, 0, 0)
      // ); /* Opera 11.1 - 12.0 */
      // background: -moz-linear-gradient(
      //   top,
      //   rgba(0, 0, 0, 0.3),
      //   rgba(0, 0, 0, 0)
      // ); /* Firefox 3.6 - 15 */
      // background: linear-gradient(
      //   to top,
      //   rgba(0, 0, 0, 0.3),
      //   rgba(0, 0, 0, 0)
      // ); /* 标准的语法 */
      .buy {
        width: fit-content;
        height: 32px;
        margin-bottom: 16px;
        line-height: 32px;
        background: rgba(51, 51, 51, 0.39);
        border-radius: 2px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        .svg-icon {
          font-size: 24px;
          margin-right: 8px;
        }
      }
      .production_name {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .production_des {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        // min-height: 62px;
        margin-bottom: 16px;
      }
      .control {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
        background: rgba(51, 51, 51, 0.3);
        // opacity: 0.3;
        transition: 0.6s opacity;
        width: 100%;
        .progress {
          width: 0%;

          height: 4px;
          background: #fff;
          position: relative;
          .slider_circle {
            position: absolute;
            padding: 8px;
            top: -100%;
            margin-top: -10px;
            right: -14px;
            // background: pink;
            span {
              display: inline-block;
              width: 12px;
              height: 12px;
              background: #fff;
              box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
              border-radius: 50%;
              opacity: 1;
            }
          }
        }
      }
    }
    .function_box {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 80%;
      height: 163px;
      z-index: 1001;
      background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ); /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(
        top,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ); /* Opera 11.1 - 12.0 */
      background: -moz-linear-gradient(
        top,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ); /* Firefox 3.6 - 15 */
      background: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ); /* 标准的语法 */
      .svg-icon {
        color: #fff;
        font-size: 32px;
        position: absolute;
        right: 10px;
        bottom: 20px;
      }
    }
  }
  .empty {
    position: absolute;
    z-index: 9;
    width: 100%;
    top: 0;
    bottom: 0;
    // background: #fb5d20;
    background: rgb(0, 35, 125);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .svg-icon {
      font-size: 80px;
      margin-bottom: 10px;
    }
    p {
      color: #c9c9c9;
      font-size: 20px;
    }
  }
  .van-loading {
    position: absolute;
    top: 52%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
    z-index: 999;
  }
  // .share_box {
  //   position: fixed;
  //   bottom: -300px;
  //   width: 100%;
  //   z-index: 1002;
  //   background: #fff;
  //   border-top-left-radius: 10px;
  //   border-top-right-radius: 10px;
  //   transition: 0.1s all;
  // }
  ::v-deep .goods_box {
    height: 85%;
    max-height: 85%;
    #goods {
      position: unset;
      height: 100%;
      .goods_box > .van-goods-action {
        height: 80px;
        align-items: flex-start;
        padding-top: 8px;
      }
      .content {
        padding-bottom: 44px;
      }
      .van-goods-action {
        // height: 80px;
        // align-items: flex-start;
        // padding-top: 8px;
        .buy {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}

video {
  object-position: 0 0;
}
.play_btn {
  position: absolute;
  top: 46%;
  right: 0;
  left: 0;
  // height: 60px;
  background: none;
  border: none;
  width: 100%;
}
.icon_play {
  height: 60px;
  font-size: 60px;
  color: #c9c9c9;
  opacity: 0.76;
}

.wrapper {
  position: absolute;
  top: 50%;
  color: #fff;
  padding: 30px;
  background: rgba(251, 78, 16, 0.6);
  font-size: 16px;
  left: 50%;
  text-align: center;
  margin-left: -75px;
  margin-top: -75px;
  border-radius: 14px;
  transition: 0.2s all;
  img {
    width: 60px;
    height: 60px;
    background: #fff;
    border-radius: 50%;
  }
  span {
    background: #fff;
    display: block;
    color: #333;
    line-height: 24px;
    border-radius: 30px;
    padding: 6px 10px;
    margin-top: 10px;
  }
}
</style>
<style>
.van-toast {
  z-index: 999999 !important;
}

/* .van-popup  */
</style>
